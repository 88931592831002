import { graphql, HeadProps, type PageProps } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { MapSlicesToComponents } from "../components/MapSlicesToComponents"
import { SEO } from "../components/SEO"
import { useSiteSettings } from "../hooks/useSiteSettings"

import type { BlogPageQuery } from "../graphql.gen"
import type { AllBlogPostsData } from "../slices/PageDataBodyAllBlogPosts"

export interface BlogPageContext {
	limit: number
	skip: number
	totalPages: number
	currentPage: number
	categoryUid?: string
}

const BlogPage = ({
	data,
	location,
	pageContext,
}: PageProps<BlogPageQuery, BlogPageContext>) => {
	const page = data.prismicPage

	const posts = data.allPrismicBlogPost.nodes
	const categories = data.allPrismicBlogCategory.nodes

	const bodySlices = page?.data.body ?? []

	const allBlogPostsData: AllBlogPostsData = {
		__typename: "PrismicPageDataBodyAllBlogPosts",
		posts,
		categories,
		totalPages: pageContext.totalPages,
		currentPage: pageContext.currentPage,
		paginationIndexPath: pageContext.categoryUid
			? `/blog/${pageContext.categoryUid}/`
			: `/blog/`,
	}

	const isLastSliceCTA =
		bodySlices[bodySlices.length - 1].__typename ===
		"PrismicPageDataBodyCallToAction"

	const slices = isLastSliceCTA
		? // If the CTA slice is last, inject the all blog posts right before it.
		  [
				...bodySlices.slice(0, -1),
				allBlogPostsData,
				bodySlices[bodySlices.length - 1],
		  ]
		: // Otherwise, make it the last slice.
		  [...bodySlices, allBlogPostsData]

	return <MapSlicesToComponents slices={slices} location={location} />
}

export const Head = ({
	data,
	location,
}: HeadProps<BlogPageQuery, BlogPageContext>) => {
	const settings = useSiteSettings()
	const page = data.prismicPage

	return (
		<SEO
			siteName={settings.siteName}
			siteDescription={settings.siteDescription}
			pageTitle="Blog"
			pathname={location.pathname}
			meta={{
				description: page?.data.meta_description?.text,
				title: page?.data.meta_title?.text,
			}}
			twitter={{
				username: settings.twitter.username,
				cardImageUrl:
					page?.data.twitter_card?.url ?? settings.twitter.cardImageUrl,
			}}
			openGraph={{
				cardImageUrl:
					page?.data.open_graph_image?.url ?? settings.openGraph.cardImageUrl,
			}}
		/>
	)
}

export default withPrismicPreview(BlogPage)

export const query = graphql`
	query BlogPage($limit: Int!, $skip: Int!, $categoryUid: String) {
		allPrismicBlogPost(
			limit: $limit
			skip: $skip
			sort: { fields: data___sortDate, order: DESC }
			filter: { data: { category: { uid: { eq: $categoryUid } } } }
		) {
			nodes {
				_previewable
				uid
				url
				data {
					title {
						text
					}
					summary {
						richText
					}
					image {
						alt
						gatsbyImageData(width: 400, sizes: "25rem", layout: FULL_WIDTH)
					}
					category {
						document {
							... on PrismicBlogCategory {
								__typename
								_previewable
								uid
								data {
									name {
										text
									}
								}
							}
						}
					}
				}
			}
		}

		allPrismicBlogCategory {
			nodes {
				uid
				data {
					name {
						text
					}
				}
			}
		}

		prismicPage(uid: { eq: "blog" }) {
			...PageData
		}
	}
`
